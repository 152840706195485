import firebase from 'firebase/app'

export default async function registerVerifyNumber(payload) {
  try {
    const mailaddress = payload
    // 乱数を生成
    const min = 1000
    const max = 9999
    const verifyNumber = Math.floor(Math.random() * (max + 1 - min)) + min

    let isRegisteredGuest = false
    let registeredData = {}
    await firebase.firestore().collection('guest')
      .where('mailaddress', '==', mailaddress)
      .get()
      .then(sn => {
        if (sn.empty) {
          isRegisteredGuest = false
        } else {
          isRegisteredGuest = true
          sn.forEach(doc => {
            registeredData = doc.data()
          })
        }
      })

    let docID = ''
    if (isRegisteredGuest) {
      docID = registeredData.docID
    } else {
      const promise = await firebase.firestore().collection('guest').doc()
      docID = promise.id
    }
    const data = {
      insertTime: new Date(),
      docID,
      isVerified: false,
      mailaddress,
      verifyNumber,
    }
    await firebase.firestore().collection('guest').doc(docID).set(data, { merge: true })

    const response = {
      status: 'success',
      mailaddress,
      verifyNumber,
    }
    return response
  } catch (error) {
    const response = {
      status: 'error',
      msg: error,
    }
    window.console.log('🌺', error)
    return response
  }
}
